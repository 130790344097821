<template>
  <NuxtLink to="/" class="box" gap="sm" :title="$t('go-home')">
    <template v-if="type === 'symbol-text'">
      <div class="box" valign="center" gap="sm">
        <Image
          :image="globals.getImage(`/base/logo-symbol-primary.webp`)"
          :width="width"
          :height="height"
          :alt="`${$t('site-name')}`"
        />
        <span>{{ text }}</span>
      </div>
    </template>
    <template v-else-if="type === 'symbol'">
      <Image
        :image="globals.getImage(`/base/logo-symbol-primary.webp`)"
        :width="width"
        :height="height"
        :alt="`${$t('site-name')}`"
      />
    </template>
    <template v-else-if="type === 'company'">
      <Image
        :image="globals.getImage(`/base/logo-primary.webp`)"
        :width="longwidth"
        :height="longheight"
        :alt="`${$t('site-name')}`"
      />
    </template>
    <template v-else>
      <div class="box" gap="sm" valign="center">
        <Image
          :image="globals.getImage(`/base/logo-symbol-primary.webp`)"
          :width="width"
          :height="height"
          :alt="`${$t('site-name')}`"
        />
        <Image
          :image="globals.getImage(`/base/logo-primary.webp`)"
          :width="longwidth"
          :height="longheight"
          :alt="`${$t('site-name')}`"
        />
      </div>
    </template>
  </NuxtLink>
</template>

<script lang="ts">
export default {
  name: 'global-logo',
  props: {
    type: {
      type: String,
      default () {
        return ''
      }
    },
    text: {
      type: String,
      default () {
        return ''
      }
    },
    width: {
      type: String,
      default () {
        return ''
      }
    },
    height: {
      type: String,
      default () {
        return ''
      }
    },
    longwidth: {
      type: String,
      default () {
        return ''
      }
    },
    longheight: {
      type: String,
      default () {
        return ''
      }
    }
  },
  setup () {
    onBeforeMount ( async () => {
      const images: any = []
      function preload(...args: string[]) {
        for (let i = 0; i < args.length; i++) {
          images[i] = new Image()
          images[i].src = args[i]
        }
      }
      preload(
        globals.getImage(`/base/logo-symbol-primary.webp`),
        globals.getImage(`/base/logo-primary.webp`)
      )
    })
  }
}
</script>
